<template lang="pug">
validation-provider(#default='{ errors }' :name="'affairIdRepartition-'+index" rules="required")
  ejs-dropdownlist.w-100(@select="emitAffairId" :id="'affairIdRepartition-'+index" popupHeight='300px' :dataSource="affairsListFiltered" :fields="{ text: 'displayLabel', value: 'id' }" :value='item')
  small.text-danger {{ errors[0] }}
</template>

<script>
import { mapGetters } from "vuex";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";
configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");
export default {
  props: {
    companyId: {
      type: Number | null,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: String | null,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["affairsList"]),
    affairsListFiltered() {
      if (this.companyId == null) {
        return [];
      }
      return this.affairsList.filter((el) => el.company?.id == this.companyId);
    },
  },
  methods: {
    emitAffairId(e) {
      this.$emit("emitAffairId", {
        affairId: e.itemData.id,
        index: this.index,
      });
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
</script>
